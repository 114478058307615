import * as React from 'react';
import { Link } from 'gatsby';
import { Embed } from 'semantic-ui-react';
import { withLayout } from '../../../components/layout';
import { H1, H2 } from '../../../components/mdx_blocks/header';

// Youtube IDs for the videos used in this case study
const yt_id_1 = "Ek4qMuo7tXY";
const yt_id_2 = "7aSkqctOlqQ";
const yt_id_3 = "urWbcL5gO8Q";

function PhasedArray() {
  return (
    <>
      <H1>Phased Array Modelling for Ultrasonic Applications</H1>
      <p>
        Forward simulation of elastic wave propagation is a computational
        technique that can be used to model the behavior of ultrasonic waves
        generated by phased array transducers. These simulations allow
        researchers and engineers to understand and optimize the performance of
        phased array systems before physical implementation.
      </p>
      <p>
        A simulation allows one to understand the complex nature of an
        ultrasonic wave, including the different wave modes propagating through
        a material, origins of unknown echoes and variations in amplitude of
        the A-Scan.
      </p>
      <p>
        In this case study we will demonstrate a basic example of how the focal
        point can be changed by using different time delays for the individual
        elements in a linear ultrasonic phased array probe.
      </p>
      <H2>Simulation Set-up</H2>
      <p>
        The transducer used is a 5 MHz linear array, having 10 elements with a
        length of 6 mm and a 0.6 mm pitch. No wedge is used in this simulation
        and the probe is placed in direct contact with the glass plate
        specimen. The glass plate has a longitudinal (L) wave velocity of 5766
        m/s, and a shear (S) wave velocity of 3450 m/s. The glass plate is 20
        mm thick and 80 mm wide.
      </p>
      <p>
        A 2D simulation was set up to run for 5 microseconds for 3 different
        focal lengths, which is enough to see the first reflection of the
        L-wave from the backwall.
      </p>
      <H2>Results</H2>
      <p>
        The compute time for each of these simulations is less than 5 seconds
        using a typical laptop (2024 MacBook Air). The propagating modes are
        split and visualized as the L- and S-waves below using ParaView.
      </p>
      <p>
        This first video shows the 5L10 transducer with the focusing depth set
        to 10 mm.
      </p>

      <Embed
        placeholder={`https://img.youtube.com/vi/${yt_id_1}/0.jpg`}
        source="youtube"
        id={yt_id_1}
      />
      <p>In the second simulation we change the focusing depth to 5 mm.</p>
      <Embed
        placeholder={`https://img.youtube.com/vi/${yt_id_2}/0.jpg`}
        source="youtube"
        id={yt_id_2}
      />
      <p>
        Finally, the third simulation shows setting the focusing depth to a
        larger value (here changed to 100 mm). The wavefield here is similar to
        a conventional transducer.
      </p>
      <Embed
        placeholder={`https://img.youtube.com/vi/${yt_id_3}/0.jpg`}
        source="youtube"
        id={yt_id_3}
      />
      <H2>Conclusion</H2>
      <p>
        Through Salvus simulations, ultrasonic focusing can be evaluated and
        optimized in a reduced time frame. This simulation capability allows
        for new insights to be gained to predict the performance of phased
        array transducers and optimize its array parameters, and serves as an
        educational tool for understanding phased array principles and
        behavior.
      </p>
      <p>
        You can see practical experiments similar to our simulations over on{" "}
        <Link to='https://NDT.net'>NDT.net</Link> with Ed Ginzel's excellent
        series of photo-elastic visualizations.
      </p>
    </>
  );
}

export default withLayout(PhasedArray);
